@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
}

.text {
  font-weight: var(--font-weight-bold);
  line-height: 23px;
  margin-bottom: 30px;

  @include devices(md-up) {
    font-size: rem(19);
    line-height: 25px;
  }
}

.button {
  width: 100%;
  margin-bottom: spacing(10);
  height: 40px;

  @include devices(md-up) {
    max-width: 300px;
  }
}

.label {
  line-height: 24px;
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  color: var(--contextual-colors-brand-primary);

  @include devices(md-up) {
    font-size: rem(16);
    line-height: 20px;
  }
}
