@use "@styles/partials/breakpoints" as *;

.telLink {
  text-decoration: underline dashed;
  color: inherit;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  @include devices(md-up) {
    cursor: default;
    text-decoration: none;
    color: var(--text-primary);
  }
}
