@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  :global(.slick-track) {
    display: flex;
    gap: spacing(3);
  }

  :global(.slick-list) {
    margin-bottom: spacing(3);
  }

  :global(.slick-slide) {
    display: inline-flex;
    height: auto;

    > div {
      display: flex;
    }
  }

}

.sliderContainer {
  container-type: inline-size;
  container-name: MostRecentReviewsSlider;
}

.dots {
  bottom: -10px;

  li button:hover::before {
    opacity: 1;
    color: var(--grey-400);
  }

  li button::before {
    transition: color 0.2s;
    font-size: rem(32);
    color: var(--grey-300);
    opacity: 1;
  }

  & :global(li.slick-active) button::before {
    color: var(--primary-main);
    opacity: 1;
  }
}
