@use "@styles/partials/functions" as *;

.ratingTextPrimary {
  line-height: rem(15);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  margin-left: spacing(0.5);
}

.ratingTextSecondary {
  line-height: rem(15);
}

.ratingIcons {
  color: var(--contextual-colors-attention);
}
