@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.square {
  background-color: var(--action-selected);
  font-size: rem(16);
  width: 50px;
  height: 50px;
  border-radius: 4px;
  color: var(--primary-main);
  border: 1px solid var(--contextual-colors-bg-primary-accent);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  @include devices(md-up) {
    width: 64px;
    height: 64px;
    font-size: rem(19);
    font-weight: var(--font-weight-bold);
  }

  &:hover {
    @include devices(md-up) {
      background-color: var(--contextual-colors-brand-primary);
      color: var(--common-white);
    }
  }

  &:first-child::after {
    position: absolute;
    content: "Sehr schwierig";
    color: var(--contextual-colors-ui-secondary);
    font-size: rem(12);
    font-weight: 400;
    left: 0;
    top: 50px;
    white-space: nowrap;
    text-align: left;
    line-height: 24px;

    @include devices(md-up) {
      font-size: rem(14);
      top: 74px;
      line-height: 19px;
    }
  }

  &:last-child::after {
    position: absolute;
    content: "Sehr leicht";
    color: var(--contextual-colors-ui-secondary);
    font-size: rem(12);
    font-weight: var(--font-weight-regular);
    right: 0;
    top: 50px;
    white-space: nowrap;
    text-align: right;
    line-height: 24px;

    @include devices(md-up) {
      font-size: rem(14);
      top: 74px;
      line-height: 19px;
    }
  }
}
