@use "@styles/partials/functions" as *;

.root {
  font-size: rem(12);
  color: var(--contextual-colors-ui-secondary);
  line-height: 1.2;
}

.title {
  font-size: inherit;
  line-height: inherit;
  font-weight: var(--font-weight-medium);
}

.body {
  font-size: inherit;
  line-height: inherit;
}
