@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.listHeadline {
  width: 100%;

  @include devices(md-up) {
    text-align: center;
  }
}

.listSubHeadline {
  text-align: center;
}

.list {
  padding: 0;
  justify-content: center;
}

.root {
  list-style: none;
}

.item {
  background-color: var(--common-white);
  border-radius: 8px;
  padding-bottom: spacing(2);
}

.active {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);
}

.itemImageWrapper {
  display: block;
  padding: spacing(2, 1, 1);
  width: 100%;
}

.itemHeadline {
  font-size: rem(12);
  margin-bottom: 0;
  padding: spacing(0, 1);
  text-align: center;

  @include devices(sm-down) {
    overflow: hidden;
    position: relative;
    max-height: 52px;

    &:before {
      position: absolute;
      content: "...";
      right: 0;
      bottom: 0;
      background-color: var(--common-white);
    }

    &:after {
      position: absolute;
      content: "";
      right: 0;
      width: spacing(1);
      height: 1.5em;
      background-color: var(--common-white);
    }
  }
  @include devices(sm-down) {
    max-height: 86px;
  }
}

.image {
  width: 100%;
  height: auto;
}
