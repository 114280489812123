@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: spacing(1, 0);
  width: 100%;
}

.bar {
  flex-grow: 1;
  height: 12px;
  background-color: var(--contextual-colors-ui-secondary);
  border-radius: 5px;
  margin: spacing(0, 2);

  @include devices(md-up) {
    flex-basis: 75%;
    flex-grow: 0;
    margin: spacing(0, 3, 0, 1);
    max-width: 75%;
  }
}

.progressBar {
  background-color: var(--contextual-colors-attention);
  border-radius: 5px;
  height: 12px;
}

.rating {
  margin: spacing(0, 1);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
}

.simpleRating {
  flex-basis: 35px;
}

.starRating {
  flex-basis: 45px;
  display: inline-flex;
}

.count {
  font-size: rem(12);
  text-align: right;

  @include devices(sm-up) {
    text-align: left;
  }

  @include devices(md-up) {
    font-size: rem(14);
  }
}
