@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: spacing(3, 1, 0);
}

.rootDesktop {
  @include devices(md-up) {
    flex-wrap: nowrap;
    padding: spacing(3);
  }
}

.title {
  position: relative;
  margin: spacing(0, 0, 3);
  font-size: rem(16);
  font-weight: 500;
  color: var(--text-secondary);
}

.titleText {
  background-color: var(--common-white);
  display: inline-block;
  position: relative;
  padding: spacing(0, 2);
}

.column {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding-bottom: spacing(4);
}
