@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  text-align: center;
  margin-bottom: 45px;

  @include devices(md-up) {
    margin-bottom: spacing(5);
  }
}

.text {
  line-height: 23px;
  font-size: rem(16);
  font-weight: var(--font-weight-bold);
  margin: 30px 0 16px;

  @include devices(md-up) {
    line-height: 25px;
    font-size: rem(19);
    margin: 40px 0 20px;
  }
}

.ratingContainer {
  display: flex;
  justify-content: center;
  gap: 6px;
}
