@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 460px;
  width: 100%;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  row-gap: spacing(3);
  margin-bottom: spacing(4.5);
  @include devices(md-up) {
    padding-left: spacing(6);
    padding-right: spacing(6);
  }
}

.formField {
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}

.formFieldCenter {
  text-align: center;
}

.formFieldBoxed {
  background-color: var(--contextual-colors-bg-main);
  padding: spacing(2, 3);
}

.formFieldText {
  font-size: rem(16);
  font-weight: 500;
  margin: 0;
}

.submitButton {
  font-size: rem(14);
  @include devices(md-up) {
    font-size: rem(16);
  }
}

.linkButton {
  color: var(--contextual-colors-ui-secondary);
}

.link {
  color: var(--primary-main);
  padding-right: spacing(0.5);
  padding-left: spacing(0.5);
  white-space: nowrap;
}

.summary {
  font-weight: 700;
  margin-bottom: spacing(1);
  margin-top: -3px;
}

.list {
  padding-left: spacing(2);
  margin-top: spacing(1);
}

.arrow {
  width: 16px;
  height: 16px;
}
