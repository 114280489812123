@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  gap: spacing(1.5);
  flex-direction: column;
  height: 100%;
}

.rootMrr {
  padding: spacing(1.5);
  background-color: var(--common-white);
  border-radius: spacing(1.5);
}

@container MostRecentReviewsSlider (min-width: 0px) {
  .rootMrr {
    max-width: 70cqi;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: spacing(0.5);

  @include devices(md-up) {
    flex-direction: row;
    align-items: center;
    gap: spacing(1.5);
  }
}

.advisor {
  display: flex;
  gap: spacing(0.5);
  align-items: center;

  @include devices(md-up) {
    gap: spacing(1.5);
  }
}

.advisorLink {
  color: var(--primary-main);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  line-height: rem(18);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.avatar {
  width: spacing(3);
  height: spacing(3);
  border-radius: 50%;
  object-fit: cover;

  @include devices(md-up) {
    width: spacing(4);
    height: spacing(4);
  }
}

.subject {
  margin: 0;
  display: flex;
  font-size: rem(16.8);
  font-weight: var(--font-weight-medium);
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include devices(md-up) {
    font-size: rem(19.2);
    line-height: 25.4px;
  }
}

.text {
  font-size: rem(14);
  line-height: 19px;
  font-weight: var(--font-weight-medium);
  word-break: break-word;

  &::before {
    content: "“";
  }

  &::after {
    content: "”";
  }

  @include devices(md-up) {
    font-size: rem(16);
    line-height: 21px;
    letter-spacing: 0.44px;
  }
}

.textMrr {
  display: -webkit-box;
  overflow: hidden;
  line-clamp: 3;
  box-orient: vertical;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-grow: 1;
}

.name {
  display: inline-flex;
  column-gap: spacing(3);
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  line-height: rem(18);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.footer {
  display: flex;
  gap: spacing(0.4);
  flex-direction: column;
}

.meta {
  display: flex;
  align-items: center;
}

.date {
  font-weight: var(--font-weight-bold);
  font-size: rem(12);
  line-height: 20px;

  @include devices(md-up) {
    font-size: rem(14);
  }
}

.rating {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 4px;
}

.ratingMrr {
  margin-left: 0;
}
