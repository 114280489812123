@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  @include devices(md-up) {
    max-width: 90%;
    margin: 0 auto;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: spacing(0, 0, 3);

  @include devices(md-up) {
    margin-bottom: spacing(7.5);
  }
}

.entry {
  height: 100%;
  overflow-y: hidden;
  border-bottom: 1px solid var(--contextual-colors-neutral);
  padding: spacing(2, 0);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  @include devices(md-up) {
    padding: spacing(3, 0);
  }
}

.centerWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.moreReviewsButton {
  font-weight: bold;
  min-width: 220px;

  @include devices(md-up) {
    min-width: 298px;
  }
}
