@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

@mixin fullscreenMedia() {
  @media only screen and (min-width: 900px) and (max-width: 1535.95px) {
    @content;
  }
}
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.fullScreen {
  @include fullscreenMedia() {
    width: 100vw;
    left: 505px;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  :global(.slick-center) {
    .item {
      transform: none !important;
    }
  }
  .item {
    overflow: hidden;
  }
}

.listHeadline {
  width: 100%;

  @include devices(md-up) {
    text-align: center;
  }
}

.subHeadline {
  margin: spacing(2, 0, 1.5);

  p {
    margin-bottom: 0;
  }

  @include devices(md-up) {
    p {
      text-align: center;
    }
  }
}

.subHeadlineStack {
  margin: spacing(2, 0, 0);

  @include devices(md-up) {
    margin: spacing(2, 0, 3.5);
  }
}

.button {
  background-color: var(--primary-main);
  border: none;
  border-radius: 50%;
  color: var(--common-white);
  height: 45px;
  margin-top: -30px;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transition: background-color 0.3s;
  width: 45px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-dark);
  }

  @include devices(lg-up) {
    height: 60px;
    width: 60px;
  }
}

.prevButton {
  left: 0;
}

.prevButtonFullScreen {
  top: spacing(-3);
  left: spacing(2);

  @include devices(xl-up) {
    left: 0;
  }
}

.nextButton {
  transform: rotate(180deg);
  right: 0;
}

.nextButtonFullScreen {
  top: spacing(-3);
  right: spacing(2);

  @include devices(xl-up) {
    right: 0;
  }
}

.carousel {
  margin: spacing(1.5, 0, 2);
  width: 100%;

  @include devices(md-up) {
    max-width: 1236px;
    margin: spacing(1.5) auto spacing(2);
  }

  :global(.slick-slide) {
    padding-right: spacing(2);

    @include devices(md-up) {
      padding: spacing(0, 2);
    }
  }

  :global(.slick-center) {
    .item {
      transform: scale(1.05);
      img {
        box-shadow: 0 5px 10px rgba(186, 205, 207, 0.25);
      }
    }
  }

  :global(.slick-current) {
    .item {
      opacity: 1;
    }
  }
}

.item {
  opacity: 0.5;
  transition: all 0.3s;
}

.itemFullScreen {
  opacity: 1;
}

.extraMargin {
  @include devices(md-up) {
    margin-top: spacing(4);
  }
}
