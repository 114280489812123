@use "@styles/partials/functions" as *;

.root {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.rating {
  margin-right: spacing(6);
  font-weight: var(--font-weight-bold);
}
