@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
}

.info {
  margin-bottom: 25px;
  line-height: 18px;
  font-weight: var(--font-weight-medium);
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
    line-height: 21px;
    margin-bottom: 35px;
  }
}

.question {
  font-weight: var(--font-weight-bold);
  font-size: rem(16);
  margin-bottom: 12px;
  line-height: 23px;

  @include devices(md-up) {
    margin-bottom: 18px;
  }
}

.textareaWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: spacing(1);

  @include devices(md-up) {
    max-width: 400px;
  }

  span {
    font-size: rem(12);
    margin-top: spacing(0.5);

    &.error {
      color: var(--error-main);
    }
  }
}

.textarea {
  height: 147px !important;
  width: 100%;
  padding: 12px;
  background-color: var(--action-selected);
  border: 1px solid var(--contextual-colors-bg-primary-accent);
  border-radius: 4px;
  resize: none;
  outline-color: var(--contextual-colors-brand-primary);
  font-family: inherit;

  &::placeholder {
    font-weight: var(--font-weight-regular);
    line-height: 18px;
    font-size: rem(14);
  }

  @include devices(md-up) {
    max-width: 400px;
  }
}

.button {
  width: 100%;
  height: 40px;

  @include devices(md-up) {
    max-width: 300px;
  }
}

.buttonDisabled {
  background-color: var(--contextual-colors-brand-primary-inactive);
}

.buttonLabel {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
}
