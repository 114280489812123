@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.paperBody {
  position: relative;
  padding: spacing(2, 5);

  @include devices(md-up) {
    padding: spacing(4, 5);
  }
}

.icon {
  width: 51px;
  height: 51px;
  margin-bottom: spacing(2);

  @include devices(md-up) {
    margin-bottom: spacing(3);
    width: 55px;
    height: 55px;
  }
}

.body {
  font-size: rem(16);
  margin-bottom: spacing(1);
}

.subtitle {
  display: none;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.button {
  margin-bottom: spacing(2);

  @include devices(sm-up) {
    width: 300px;
  }
}

.socialBlock {
  color: var(--text-secondary);
  margin-top: spacing(2);
  text-align: center;
}

.socialTitle {
  font-weight: var(--font-weight-medium);
}

.socialLinks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;

  & > li {
    margin: 0 6px;
  }
}
