@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-bottom: spacing(3);

  @include devices(md-up) {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: spacing(6);
  }
}
