@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--contextual-colors-ui-secondary);
}

.footerSpacer {
  margin-bottom: spacing(1);
  @include devices(md-up) {
    margin-bottom: spacing(2);
  }
}

.link {
  color: var(--primary-main);
  white-space: nowrap;
}

.footerRow {
  padding: spacing(2, 1);
  @include devices(md-up) {
    padding: spacing(2);
  }
}

.footerRowBg {
  background-color: var(--contextual-colors-bg-main);
}

.icon {
  margin-right: spacing(1);
  display: inline-block;
  vertical-align: middle;
}

.footerList {
  list-style: none;
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
  font-size: rem(12);

  @include devices(md-up) {
    font-size: rem(14);
  }
}

.footerListItem {
  text-align: left;
  margin-bottom: spacing(1);
}

.footerListItemCenter {
  text-align: center;
}

.footerListTitle {
  font-size: rem(14);
  font-weight: 700;
  margin-bottom: spacing(1);
  margin-top: 0;
}

.footerEntryText {
  margin: spacing(0, 0, 2);
}
