@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

// vars duplicating from component
$image-size-width-md: 282px;
$image-size-width-lg: 302px;

$image-collage-sizes-sm-width: 700px;
$image-collage-sizes-sm-height: 125px;
$image-collage-sizes-md-width: 282px;
$image-collage-sizes-md-height: 305px;

$image-about-container-sizes-sm-width: 116px;
$image-about-container-sizes-sm-height: 104px;
$image-about-container-sizes-md-width: 302px;
$image-about-container-sizes-md-height: 147px;

.imageWrapper {
  overflow: hidden;
  background-color: var(--common-white);
  border-radius: 4px;

  @include devices(md-up) {
    height: 305px;
    width: $image-size-width-md;
    border-radius: 0;
  }

  @include devices(lg-up) {
    width: $image-size-width-lg;
  }
}

.imageWrapperCollage {
  background-color: transparent;
  @include devices(sm-up) {
    height: 180px;
  }

  @include devices(md-up) {
    height: 305px;
  }
}

.imageWrapperCollageAboutSection {
  display: flex;
  height: auto;

  @include devices(md-up) {
    height: 370px;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
}

.containerImgAboutSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $image-about-container-sizes-sm-width;
  height: $image-about-container-sizes-sm-height;

  @include devices(md-up) {
    width: $image-about-container-sizes-md-width;
    height: $image-about-container-sizes-md-height;
  }
}

.image {
  display: block;

  @include devices(md-up) {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.imageCollage {
  width: 100%;
  height: $image-collage-sizes-sm-height;
  object-fit: cover;
  margin: 0;

  @include devices(md-up) {
    height: auto;
  }
}

.collage {
  position: relative;
  margin-bottom: spacing(2);

  @include devices(md-up) {
    max-width: calc($image-size-width-md + 109px);
  }

  @include devices(lg-up) {
    max-width: calc($image-size-width-lg + 109px);
  }
}

.link {
  display: block;
  position: relative;
}

.infoWrapperCollage {
  width: 98%;
  max-width: none;
  position: relative;
  margin-top: -15px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: var(--background-default);

  @include devices(sm-up) {
    position: absolute;
    left: 0;
    bottom: 15px;
    margin-top: auto;
    width: 94%;
  }

  @include devices(md-up) {
    width: 100%;
    max-width: 217px;
    max-height: 95%;
    bottom: auto;
    top: 198px;
    left: 193px;
  }
}

.info {
  color: var(--primary-dark);
}

.infoCollageAboutSection {
  color: var(--primary-dark);

  @include devices(md-up) {
    text-align: center;
  }
}

.itemHeadline {
  word-wrap: break-word;
  font-weight: var(--font-weight-bold);
}

.itemHeadlineCollage {
  margin: 0;
  color: var(--primary-main);
  font-size: rem(24);
  line-height: 1.15;

  @include devices(sm-up) {
    font-size: rem(30);
    line-height: 1.27;
  }
}

.itemHeadlineCollageAboutSection {
  margin: spacing(1, 0, 0, 2);
  color: var(--text-primary);
  font-size: rem(24);
  line-height: 1.13;

  @include devices(md-up) {
    margin: 0;
    line-height: 1.39;
    padding: spacing(1.25, 0, 1, 0);
    display: flex;
    justify-content: center;
  }
}

.subHeadline {
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.subHeadlineCollage {
  color: var(--text-primary);
  font-weight: var(--font-weight-bold);
  margin: 0;
  max-width: 218px;
}

.subHeadlineCollageAboutSection {
  color: var(--text-secondary);
  font-weight: var(--font-weight-regular);
  margin: spacing(0, 2, 1.5, 2);
  max-width: none;

  @include devices(md-up) {
    margin: spacing(1, 0, 0, 0);
    font-size: rem(16);
    line-height: 1.75;
    letter-spacing: 0.35px;
  }
}

.wrapperLink {
  display: block;
  font-size: 0;
}
