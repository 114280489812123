@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.button {
  min-width: 220px;
  margin-top: spacing(5);
  width: 100%;

  @include devices(md-up) {
    margin-top: spacing(7.5);
    width: auto;
  }

  @include devices(lg-up) {
    min-width: 302px;
  }
}
