@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: spacing(11, 2, 2.5);
  margin: spacing(2);
  background-color: var(--common-white);

  @include devices(md-up) {
    padding: spacing(20, 2, 12);
  }
}

.icon {
  width: 100px;
  height: 100px;
  margin-bottom: spacing(8);

  @include devices(md-up) {
    width: 180px;
    height: 180px;
  }
}

.title {
  text-align: center;
  font-size: rem(22);
  margin-bottom: spacing(5);
  font-weight: var(--font-weight-medium);

  @include devices(md-up) {
    font-size: rem(36);
    font-weight: var(--font-weight-bold);
  }
}

.description {
  text-align: center;
  font-size: rem(14);
  margin-bottom: spacing(14);

  @include devices(md-up) {
    font-size: rem(23);
  }
}

.credentials {
  font-size: rem(13);
  width: 100%;

  @include devices(md-up) {
    font-size: rem(18);
    width: auto;
    display: flex;
  }

  & + .credentials {
    margin-top: spacing(1.5);
  }
}

.divider {
  display: none;

  @include devices(md-up) {
    display: block;
    margin: spacing(0, 1);
  }
}

.navBarContainer {
  justify-content: center;
}
