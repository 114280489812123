@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.paperBody {
  padding: spacing(2) !important;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  &:not(:last-child) {
    margin-bottom: spacing(2);
  }
}

.trustItem {
  display: flex;
  align-items: flex-start;
}

.iconWrapper {
  height: 24px;
  width: 24px;
  margin-right: spacing(1.5);
  flex-shrink: 0;

  @include devices(sm-up) {
    margin-right: spacing(2);
  }
}

.title {
  line-height: 1.2;
  font-size: rem(12);
  font-weight: var(--font-weight-bold);
}

.body {
  font-size: rem(12);
  line-height: 1.2;
}
