@use "@styles/partials/functions" as *;

.additionalSuggestion {
  margin-bottom: spacing(0.5);
}

.additionalTitleSuggestion {
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
  line-height: 1.3;
  margin-bottom: spacing(0.5);
}
