@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  position: relative;
}

.button {
  border: none;
  padding: 0;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
}

.sliderButton {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 32px;
  height: 40px;
  display: none;
  color: var(--common-white);

  @include devices(md-up) {
    display: block;
  }
}

.sliderPrevButton {
  left: spacing(4);
  transform: scale(-1);
}

.sliderNextButton {
  right: spacing(4);
}

.dots {
  position: absolute;
  z-index: 100;
  bottom: 8px;
  left: 24px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex !important;
  justify-content: center;

  & li {
    margin-right: spacing(2);

    @include devices(md-up) {
      margin-right: spacing(3);
    }

    &:last-child {
      margin-right: 0;
    }
  }

  :global(li.slick-active) {
    .dot {
      opacity: 1;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  background-color: var(--common-white);
  border-radius: 50%;

  @include devices(md-up) {
    width: 18px;
    height: 18px;
  }
}

.pauseButton {
  position: absolute;
  z-index: 100;
  bottom: spacing(0.5);
  right: spacing(2);
  width: 40px;
  height: 40px;
  padding: spacing(1);
  color: var(--common-white);
}
