@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  background-color: var(--contextual-colors-bg-main);
}

.rootStack {
  padding: spacing(2);
  height: 400px;
  width: 256px;

  @include devices(sm-up) {
    width: 296px;
  }

  @include devices(md-up) {
    width: auto;
  }
}

.rootArticle {
  margin-bottom: spacing(2);
}

.imageWrapper {
  height: 200px;
  width: 100%;
  position: relative;
}

.imageArticleWrapper {
  max-width: 100%;
  position: relative;
}

.imageResponsive {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.imageRegular {
  max-width: 100%;
  height: auto;
}

.headline {
  color: var(--primary-main);
  margin-top: spacing(1);
  margin-bottom: spacing(1);
  transition: color 0.3s;
  &:hover {
    color: var(--primary-dark);
  }
}

.subheadline {
  font-size: rem(16);
  margin-bottom: spacing(2);
  transition: color 0.3s;
}

.link {
  transition: color 0.3s;
  &:hover .headline {
    color: var(--primary-dark);
  }
  &:hover .subheadline {
    color: var(--text-secondary);
  }
}

.arrow {
  color: var(--primary-main);
  svg {
    transform: rotate(180deg);
  }
}
